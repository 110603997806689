'use strict';
/**
 * The category list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.items')
  .controller('categoryManagementController', function($scope, $state, DataManager,  Notification) {

    $scope.categories = [];

    $scope.categoryTableButtons = [{
      'icon':'plus',
      'title':'Ajouter',
      'function': () => {
        $state.go('items/category-management/category-creation');
      }
    }];

    $scope.categoryTableStructure = [
      {
        'title':'Dimension',
        'value':'dimension',
        'filter':'alpha'
      },
      {
        'title':'Categories',
        'value':'title',
        'filter':'alpha'
      }];

    /**
     * Initialisation function
     */
    $scope.init = function() {
      DataManager.fetchCategories(true)
        .then(data => $scope.categories = data)
        .catch(err => {
          console.log(err);
          Notification.error('Un probème est survenu lors du chargement des categories');
        });
    };

    /**
     * Link to go to the category edition page
     * @param {Object} category - the category to edit
     */
    $scope.selectCategory = function(category) {
      $state.go('items/category-management/category-creation', {'categoryId': category._id});
    };

    $scope.init();

  });
